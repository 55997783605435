'use client'

import { captureMessage, showReportDialog } from '@sentry/nextjs'
import { RefreshCw, Send } from 'lucide-react'

import { Button } from '../Button'

interface ErrorViewProps {
  error: Error & { digest?: string }
}

export function ErrorView({ error }: ErrorViewProps) {
  const refresh = (): void => {
    window.location.reload()
  }

  const openSentryCrashReportModal = () => {
    /**
     * This records an event for the current error to be able to send a crash report.
     * The reason behind this is that the sentry auto instrumentation with next
     * handles sending errors to sentry during crashes automatically and I haven't
     * found a way to connect the crash report to those event ids.
     * Capturing a message with the given error is a workaround to be able to get crash
     * reports in sentry for now until we find a solution for how to connect the report
     * directly to the auto events.
     */
    const sentryEventId = captureMessage(error.message)
    if (sentryEventId) {
      showReportDialog({
        eventId: sentryEventId,
        onClose() {
          refresh()
        },
      })
    }
  }

  return (
    <div className='flex h-fit w-full flex-1 flex-col justify-center m-auto gap-2 sm:max-w-2xl'>
      <div className='flex flex-col items-center bg-white p-10 md:rounded-3xl bg-opacity-60 gap-4'>
        <div className='text-center space-y-6'>
          <h1 className='text-3xl'>Oops! Something went wrong</h1>
          <p className='text-md text-gray-600 leading-relaxed'>
            We apologize for the inconvenience. The error has been reported to
            our developers. If you wouldd like to provide more information by
            sending a crash report, it would be greatly appreciated!
          </p>
          <div className='flex flex-col sm:flex-row gap-4 justify-center pt-4'>
            <Button
              color='light'
              onClick={refresh}
              className='w-full sm:w-auto'
            >
              <RefreshCw className='h-4 w-4' />
              Try again
            </Button>
            <Button
              onClick={openSentryCrashReportModal}
              className='w-full sm:w-auto'
              color='orange'
            >
              <Send className='h-4 w-4' />
              Send crash report
            </Button>
          </div>
        </div>
      </div>
    </div>
  )
}
