import { IMessage } from '../messages/models'

export enum MessageType {
  ReceiveMessage = 'receive:message',
  CreateMessage = 'create:message',
  ReceiveErrorMessage = 'receive:errorMessage',
  ReceiveRateLimitErrorMessage = 'receive:rateLimitError',
  ReceiveSuggestionsMessage = 'receive:suggestionsMessage',
}

export interface MessageData extends IMessage {}

export interface SuggestionsData {
  helpMeStuckOptions: string[]
  getInspirationOptions: string[]
}

export interface ReceiveMessage {
  type: MessageType.ReceiveMessage
  data: MessageData
}

export interface ReceiveSuggestionsMessage {
  type: MessageType.ReceiveSuggestionsMessage
  data: SuggestionsData
}

export interface ReceiveErrorMessage {
  type: MessageType.ReceiveErrorMessage
  data: { message: string }
}

export interface ReceiveRateLimitErrorMessage {
  type: MessageType.ReceiveRateLimitErrorMessage
  data: { message: string }
}

export interface CreateMessageData {
  message: string
  userTimezone: string
}

export interface CreateMessage {
  type: MessageType.CreateMessage
  data: CreateMessageData
}

export type WebSocketMessage =
  | ReceiveMessage
  | CreateMessage
  | ReceiveErrorMessage
  | ReceiveRateLimitErrorMessage
  | ReceiveSuggestionsMessage
