import { Static, Type } from '@sinclair/typebox'

import { MAILCHIMP_TAGS } from '../constants/mailchimp'
import { CommonReadOnlySchema } from './common'
import { SubscriptionSchema } from './product'
import { SupabaseCustomerSchema } from './supabaseCustomer'

/**
 * ------------------- User Schemas -------------------
 */

/**
 * Base schema for User properties used in creation and updates
 */
const UserBaseSchema = Type.Object(
  {
    firstName: Type.Union([Type.String(), Type.Null()]),
    lastName: Type.Union([Type.String(), Type.Null()]),
    nativeLanguageId: Type.String(),
    targetLanguageId: Type.String(),
  },
  { additionalProperties: false }
)

/**
 * Schema for creating a User
 */
export const UserCreateSchema = Type.Intersect([
  Type.Object({ id: Type.String() }, { additionalProperties: false }),
  UserBaseSchema,
])
export type UserCreateType = Static<typeof UserCreateSchema>

/**
 * Schema for patching a User
 */
export const UserPatchSchema = UserBaseSchema
export type UserPatchType = Static<typeof UserPatchSchema>

/**
 * Complete User schema
 */
export const UserSchema = Type.Intersect([
  UserCreateSchema,
  Type.Object(
    {
      avatarUrl: Type.Union([Type.String(), Type.Null()]),
      CommonReadOnlySchema,
    },
    { additionalProperties: false }
  ),
])
export type UserType = Static<typeof UserSchema>

/**
 * Schema for User's own data (e.g., /me endpoint)
 */
export const UserMeSchema = Type.Object({
  user: Type.Optional(UserSchema),
  stripeCustomer: Type.Optional(SupabaseCustomerSchema),
  activeSubscription: Type.Optional(SubscriptionSchema),
})
export type UserMeType = Static<typeof UserMeSchema>

/**
 * ------------------- Transaction Schemas -------------------
 */

/**
 * Schema for TransactionItem
 */
export const TransactionItemSchema = Type.Object(
  {
    item_id: Type.Optional(Type.String()),
    item_name: Type.Optional(Type.String()),
    affiliation: Type.String(),
    discount: Type.Number(),
    item_brand: Type.String(),
    price: Type.Optional(Type.Number()),
    quantity: Type.Number(),
  },
  { additionalProperties: false }
)
export type TransactionItemType = Static<typeof TransactionItemSchema>

/**
 * Schema for TransactionData
 */
export const TransactionDataSchema = Type.Object(
  {
    transaction_id: Type.String(),
    value: Type.Optional(Type.Number()),
    currency: Type.Optional(Type.String()),
    discount: Type.Number(),
    items: Type.Optional(Type.Array(TransactionItemSchema)),
  },
  { additionalProperties: false }
)
export type TransactionDataType = Static<typeof TransactionDataSchema>

/**
 * ------------------- User Language Setting Schemas -------------------
 */

/**
 * Base schema for User Language Setting properties used in patching and creating
 */
const UserLanguageSettingBaseSchema = Type.Object(
  {
    proficiencyLevel: Type.Optional(
      Type.Union([
        Type.Literal('beginner'),
        Type.Literal('intermediate'),
        Type.Literal('advanced'),
      ])
    ),
    aiBilingualMode: Type.Optional(
      Type.Union([
        Type.Literal('bilingual'),
        Type.Literal('monolingual'),
        Type.Literal('mixed'),
      ])
    ),
    aiFormalityMode: Type.Optional(
      Type.Union([Type.Literal('casual'), Type.Literal('formal')])
    ),
    aiCorrectionMode: Type.Optional(
      Type.Union([Type.Literal('flow'), Type.Literal('strict')])
    ),
    aiTalkingSpeed: Type.Optional(
      Type.Union([Type.Literal('slow'), Type.Literal('normal')])
    ),
    aiTalkingSpeedInt: Type.Optional(Type.Number()),
    aiVoiceIndex: Type.Optional(Type.Number()),
  },
  { additionalProperties: false }
)

/**
 * Schema for patching a User Language Setting
 */
export const UserLanguageSettingPatchSchema = UserLanguageSettingBaseSchema
export type UserLanguageSettingPatchType = Static<
  typeof UserLanguageSettingPatchSchema
>

/**
 * Schema for creating a User Language Setting
 */
export const UserLanguageSettingCreateSchema = Type.Intersect([
  Type.Object(
    { targetLanguageId: Type.String() },
    { additionalProperties: false }
  ),
  UserLanguageSettingBaseSchema,
])
export type UserLanguageSettingCreateType = Static<
  typeof UserLanguageSettingCreateSchema
>

/**
 * Complete User Language Setting schema
 */
export const UserLanguageSettingSchema = Type.Intersect([
  Type.Object({ id: Type.String() }, { additionalProperties: false }),
  UserLanguageSettingCreateSchema,
  CommonReadOnlySchema,
])
export type UserLanguageSettingType = Static<typeof UserLanguageSettingSchema>

/**
 * ------------------- Mailchimp Schemas -------------------
 */

/**
 * Schema for creating Mailchimp Tags
 */
export const MailchimpTagsCreateSchema = Type.Object(
  {
    tags: Type.Array(
      Type.Union([
        Type.Literal(MAILCHIMP_TAGS.NEWSLETTER),
        Type.Literal(MAILCHIMP_TAGS.ACCOUNT_CREATED),
        Type.Literal(MAILCHIMP_TAGS.STRIPE_ACTIVE_SUBSCRIBER),
        Type.Literal(MAILCHIMP_TAGS.STRIPE_FORMER_SUBSCRIBER),
        Type.Literal(MAILCHIMP_TAGS.TEST_USER),
      ])
    ),
  },
  { additionalProperties: false }
)
export type MailchimpTagsCreateType = Static<typeof MailchimpTagsCreateSchema>
