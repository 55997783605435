import { Static, Type } from '@sinclair/typebox'

export const TranslateCreateSchema = Type.Object({
  text: Type.String(),
  sourceLanguageCode: Type.String(),
  targetLanguageCode: Type.String(),
})

export const TranslateCreateResponseSchema = Type.Intersect(
  [
    Type.Object({
      text: Type.String(),
    }),
  ],
  {
    additionalProperties: false,
  }
)

export type TranslateResponseType = Static<typeof TranslateCreateResponseSchema>
